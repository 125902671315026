import React from 'react'
import CardItem from './CardItem'
import './Card.css';

function Cards() {
    return (
        <div className='cards'>
            <h1>OUR SERVICES</h1>
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">
                        <CardItem 
                            src="images/andios.jpg"
                            text="Mobile application development is the process of creating software applications that run on a mobile device, and a typical mobile application utilizes a network connection to work with remote computing resources.  At MM-iCT, we cover all the four major development approaches when building mobile applications that is Native Mobile Applications, Cross-Platform Applications, Hybrid Mobile Applications  and Progressive Web Applications"
                            label='Mobile Development'
                            // path='/services'
                        />
                        <CardItem 
                            src="images/WebDev-01.svg"
                            label='Web Development'
                            text="Web development is the work involved in developing a Web site for the Internet or an intranet. Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services. At MM-iCT we cover Web design, content development, client liaison, client-side/server-side scripting, Web server and network security configuration, and e-commerce development"
                            // path='/services'
                        />
                    </ul>
                    <ul className="cards__items">
                        <CardItem 
                            src="images/hosted-pbx.jpeg"
                            label='IP Telephony & PABX'
                            text="Voice over Internet Protocol (VoIP), also called IP telephony, is a method and group of technologies for the delivery of voice communications and multimedia sessions over Internet Protocol (IP) networks, such as the Internet. Get in touch with us for provisioning of communications services (voice, SMS, voice-messaging) over the Internet such as Session Initiated Protocols -- SIP Trunks"
                            // path='/services'
                        />
                        <CardItem 
                            src="images/cabling.jpg"
                            label='Network & Security'
                            text="Network security consists of the policies, processes and practices adopted to prevent, detect and monitor unauthorized access, misuse, modification, or denial of a computer network and network-accessible resources. We offer the best in-class practices, hardware and software to achieve this for our clients"
                            // path='/services'
                        />
                        <CardItem 
                            src="images/support.jpg"
                            label='Maintenance & Support'
                            text="We offer a series of well-defined services on an ongoing basis, with well-defined response & resolution times for a fixed rate or a flat fee. Such may include 24/7 monitoring of services & servers, help desk support for daily computer issues, on-site visits by a technician when issues can't be resolved remotely.[citation needed] & IT project management."
                            // path='/services'
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards;