
import React from 'react'
import emailjs from 'emailjs-com';
import { Avatar, Button, Grid, Paper, TextField } from '@material-ui/core';
import './contactForm.css'

function ContactUs() {

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_0jzeibc', 'template_hu1bwfk', e.target, 'user_na8A0XRj0eMOQ4jJkazSN')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
    }

    return (
            <Grid container>
                <Paper elevation={10} className="paperStyle">
                    <Grid align="center">
                        <h2>CONTACT US</h2>
                        <Avatar>
                            <img src="../images/logoo.svg" width="45" height="45" alt="contact"></img>
                        </Avatar>
                        
                    </Grid>
                    <form onSubmit={sendEmail}>
                        <TextField 
                            name="name" 
                            label="Name" 
                            placeholder="Name" 
                            variant="outlined" 
                            fullWidth 
                            required 
                            style={{paddingBottom: '10px', marginTop: '30px'}} 
                        />
                        <TextField 
                            name="email" 
                            label="Email" 
                            placeholder="Email Address"
                            variant="outlined" 
                            fullWidth 
                            required 
                            style={{paddingBottom: '10px'}}  
                        />
                        <TextField 
                            name="message" 
                            label="Message" 
                            placeholder="Enter your message - 150 Characters max..." 
                            variant="outlined" 
                            fullWidth 
                            multiline 
                            style={{paddingBottom: '10px', marginTop: '15px'}} 
                            inputProps={{maxLength: 150}} 
                        />
                        <Button 
                            type="submit" 
                            color="secondary" 
                            variant="contained" 
                            fullWidth> 
                            Send Message
                        </Button>
                    </form>
                </Paper>
            </Grid>
    )
}

export default ContactUs;
