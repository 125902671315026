
import React from 'react';
import './HeroSection.css';
import '../App.css';

function HeroSection() {
    return (
        <>
        <div className='hero-container'>
            {/* <video src="/videos/video-2.mp4" autoPlay loop muted />  */}
            <div className='text'>
                <h1>VOIP SOLUTIONS</h1>
                <p>...with Software Advice front runners 2021.</p>
            </div>
            <div className='xcally-logo'>
                <img src="../images/xcallybadge-whiteshadow-01.svg" width="335" height="335" alt="logo"></img>
            </div>
        </div>
            </>
    )
}

export default HeroSection
