
import { Grid } from '@material-ui/core';
import React from 'react';
import '../../App.css';

export default function Products() {
    return (
        <Grid>
            <p className='products'>Coming Soon!</p> 
            

        </Grid>
    )
}
